import { AngularFireMessaging } from '@angular/fire/messaging';
import { Firebase } from '@ionic-native/firebase/ngx';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from '@env/environment';
import { first } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class RemoteNotificationsService {

  constructor(
    public firebaseNative: Firebase,
    public afm: AngularFireMessaging,
    private platform: Platform
  ) {
    // if (environment.production === false) {
    //   this.fun.functions.useFunctionsEmulator('http://localhost:5000');
    // }
  }

  async getToken() {
    let token: string;

    if (this.platform.is('pwa') && !this.platform.is('ios')) {
      const currentPermission = Notification.permission;
      if (currentPermission === 'default') {
        const result = await Notification.requestPermission();
        if (result === 'granted') {
          token = await this.afm.getToken.pipe(first()).toPromise();
        }
      } else if (currentPermission === 'granted') {
        token = await this.afm.getToken.pipe(first()).toPromise();
      }
    } else if (!environment.production) {
      token = 'tokentest';
    }
    /*
    else if (this.platform.is('android')) {
      token = await this.firebaseNative.getToken();
    } else if (this.platform.is('ios')) {
      token = await this.firebaseNative.getToken();
      const perm = await this.firebaseNative.grantPermission();
    }*/

    return token;
  }

  listenToNotification() {
    if (!this.platform.is('cordova')) {
      return this.afm.messages;
    } else {
      return this.firebaseNative.onNotificationOpen();
    }
  }
}
