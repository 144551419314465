import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { ItineraryBuilderDayClientService } from "@app/itinerary-builder-sdk/services/itinerary-builder-day-client.service";
import { ItineraryDay } from "@app/itinerary-builder-sdk/interfaces/itinerary-day";
import { ItineraryItem } from "@app/itinerary-builder-sdk/interfaces/itinerary-item";
import { ModalController } from "@ionic/angular";
import { LikedListingsComponent } from "../../liked-listings/liked-listings.component";

import moment from "moment";
import { FavoriteListingsComponent } from "@app/codebase/itinerariesv2/favorite-listings/favorite-listings.component";
import { environment } from "@env/environment";
import { ItineraryBuilderItineraryClientService } from "@app/itinerary-builder-sdk/services/itinerary-builder-itinerary-client.service";
import { CMSDataControllerService } from "@app/core/data/controller/cmsdata-controller.service";
import { Listing } from "@app/core/data/Listing";
import { LoadingService } from "@app/shared/services/loading.service";
import { ItineraryBuilderItemClientService } from "@app/itinerary-builder-sdk/services/itinerary-builder-item-client.service";
declare function require(name: string);

@Component({
  selector: "app-itinerary-day-cell",
  templateUrl: require("template/" +
    environment.template +
    "/html/itinerary-day-cell.component.html"),
  styles: [
    require("template/" +
      environment.template +
      "/styles/itinerary-day-cell.component.scss"),
  ],
})
export class ItineraryDayCellComponent implements OnInit {
  public environment = environment;
  @Output() stopChanged = new EventEmitter<{
    action: string;
    dayId: number;
    dayIndex: number;
    stopId: number;
    listingId?: number;
    eventId?: number;
    startTime?: string;
    endTime?: string;
  }>();
  @Output() dayChanged = new EventEmitter<{
    action: string;
    dayId: number;
    dayIndex: number;
    date?: string;
  }>();
  public date!: Date;
  @Input() public day: ItineraryDay;
  @Input() public dayIndex!: number;
  public stops!: ItineraryItem[];
  public itineraryType: string;
  public listings: Listing[] = [];
  public isPresetItinerary: boolean = false;

  constructor(
    private itineraryBuilderDayClientService: ItineraryBuilderDayClientService,
    private itineraryBuilderItineraryClient: ItineraryBuilderItineraryClientService,
    private itineraryBuilder: ItineraryBuilderItemClientService,
    private loading: LoadingService,
    private dataController: CMSDataControllerService,
    private route: ActivatedRoute,
    private modalController: ModalController
  ) {
    this.itineraryType = this.route.snapshot.queryParams.type;
  }

  async ngOnInit() {
    if (this.day.ItineraryDayDate) {
      this.date = new Date(this.day.ItineraryDayDate);
      this.stops = this.day.ItineraryItems;
    } else {
      this.date = new Date(this.day.ItineraryDate);
      this.isPresetItinerary = true;
      this.stops = await (
        await this.itineraryBuilderItineraryClient.getPresetItinerariesDaysItems()
      ).filter((item) => item.DayId == this.day.Id);
      this.stops.forEach((obj) => {
        this.dataController
          .getListingById({ listingId: obj.ListingId })
          .subscribe((resp) => {
            // console.log(resp[0])
            obj.Title = resp[0].SortKey;
            obj.ImageUrl = resp[0].Image_List;
          });
      });
    }
    console.log(this.isPresetItinerary);
  }

  getDate(date) {
    return moment(date).format("dddd, MMMM DD");
  }

  onDeleteDayTap() {
    this.dayChanged.emit({
      action: "delete",
      dayId: this.day.Id,
      dayIndex: this.dayIndex,
    });
  }

  onEditDayDateChanged(date: any) {
    const newDate = date.detail.value;
    this.dayChanged.emit({
      action: "edit",
      dayId: this.day.Id,
      dayIndex: this.dayIndex,
      date: newDate,
    });
    this.day.ItineraryDayDate = newDate;
    this.date = new Date(this.day.ItineraryDayDate);
  }

  public onStopChanged(event: {
    action: string;
    stopId: number;
    listingId?: number;
    eventId?: number;
    startTime?: string;
    endTime?: string;
  }) {
    if (event) {
      this.stopChanged.emit({
        action: event.action,
        dayId: this.day.Id,
        dayIndex: this.dayIndex,
        stopId: event.stopId,
        listingId: event.listingId,
        eventId: event.eventId,
        startTime: event.startTime,
        endTime: event.endTime,
      });
    } else {
      console.error(">>> invalid event");
    }
  }

  public trackBy(index: number, item: { Id: string }) {
    if (!item) {
      return null;
    }
    return item.Id;
  }

  async onAddElementButtonClick() {
    const modal = await this.modalController.create({
      component: FavoriteListingsComponent,
      componentProps: {
        itineraryId: this.route.snapshot.params.id,
        dayId: this.day.Id,
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data && data.updated && data.itineraryItem) {
      this.stops.push(data.itineraryItem);
    } else {
      //console.log('>>> Nothing changed');
    }
  }

  async onItemClick() {
    //console.log(item)
    await this.loading.presentLoading();
    let promises: any[] = [];
    let itemsDay = this.stops.filter((obj) => obj.DayId == this.day.Id);
    let userItinerary = (
      await this.itineraryBuilderItineraryClient.getItineraries()
    )[0];
    itemsDay.forEach(async (day) => {
      const rest = await this.itineraryBuilderDayClientService.addDayFromPreset(
        userItinerary.Id,
        this.day.ItineraryDate
      );
      promises.push(
        this.itineraryBuilder.addItemToDay(
          +userItinerary.Id,
          rest.dayId,
          "" + day.ListingId,
          "" + day.EventId,
          day.StartTime,
          day.EndTime
        )
      );
    });

    await Promise.all(promises);
    this.loading.dismiss();
  }
}
