import { settings as settings } from '../environments/settings';
export const environment = {
  ...settings,
  template: 'pwa-1.0',
  theme: {
    name: 'masoncounty',
    appName: 'MasonCounty',
    productionHostName: 'masoncounty-trueomni.web.app',
    stagingHostName: 'staging-masoncounty.web.app',
  },
  cms: {
    domainId: 1077,
    accountId: 1077,
    channelId: 1391,
    deviceId: 1049,
    formId: 158,
    postalCode: '98584',
    bandwangosPageId: 3388,
    visitorCenterId: 18004,
    main_coordinates: {
      latitude: settings.site.latitude,
      longitude: settings.site.longitude,
    },
    navigation: {
      avatar_enabled: false,
      homePageId: 4424,
      itemsPerRowCategory: 2,
      footerPageId: 0
    }
  },
  regions:{
    enabled: true,
    regionsPageId: 6977
  },
  coreAuthLib: {
    base_url: 'https://usersapi.mobimanage.com',
    client_secret: 'B856FoEr10WVBvndGjCd0Q',
  },
  firebase: {
    apiKey: "AIzaSyCsq5YNvp5dEs_EvFTh6CK6VHgXpSYJJ9M",
    authDomain: "mason-county.firebaseapp.com",
    projectId: "mason-county",
    storageBucket: "mason-county.appspot.com",
    messagingSenderId: "262966523355",
    appId: "1:262966523355:web:6d4246832c782599ae63fe",
    measurementId: "G-9F0T78L838"
  },
  messagesSDK: {
    client_id: 1631,
    client_secret: 'nBaVslSzGECoMaDod94mDA',
    base_url: 'https://pushapi.mobimanage.com'
  },
  socialWall: {
    client_id: '1077',
    client_secret: 'Z5qveSsS26h9Ejkz45mitkf59=',
    url: 'https://wall.crowdriff.com/live/24206c2e-3bba-407a-b649-8abd93f1b40b'
  },
  googleMaps: 'AIzaSyAb0SnmmZsewjJYWVDY5U3D8-oo1zKh-Jg',
  google: {
    client_id: '262966523355-1s7qlh1arfv8l3i0tluok7jf2o0us8mq.apps.googleusercontent.com',
  },
  facebook: {
    app_id: '424644036922072',
  },
  batuta: {
    api_key: 'e8e98196453d6f0bd4ffd6553d56a829',
    base_url: 'http://battuta.medunes.net/api/'
  },
  tokenUniversal: {
    token: "DWuAh7qPqHqyp8la7Os4568OuHLu6dbpyvo11ZtsIfPvAnQgZOEPQk9Ssqdlb42VqVI"
  },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
