import { HttpHeaders, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class CoreAuthLibClientUtilsService {

  private GRANT_TYPE_APPLICATION = 'application';
  private GRANT_TYPE_REFRESH_TOKEN = 'refresh_token';

  constructor() { }

  getApplicationTokenParams() {
    const params = new HttpParams({
      fromObject: {
        grant_type: this.GRANT_TYPE_APPLICATION,
        client_id: String(environment.cms.domainId),
        client_secret: environment.coreAuthLib.client_secret
      }
    });
    return params;
  }

  getApplicationTokenHeaders() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return headers;
  }

  getRefreshTokenParams(refreshToken: string) {
    const params = new HttpParams({
      fromObject: {
        grant_type: this.GRANT_TYPE_REFRESH_TOKEN,
        refresh_token: refreshToken
      }
    });
    return params;
  }

  getRefreshTokenHeaders() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return headers;
  }
}
