import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

import { GenericAlertComponent } from '../generic-alert/generic-alert.component';
import { UsersApiService } from '@app/core/users-api/users-api.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
declare function require(name:string);

@Component({
  selector: 'app-user-search-inbox-header',
  templateUrl: require('template/' + environment.template + '/html/user-search-inbox-header.component.html'),
  styles: [require('template/' + environment.template + '/styles/user-search-inbox-header.component.scss')]
})
export class UserSearchInboxHeaderComponent implements OnInit, OnDestroy {

  @Input() headerTitle!: string;

  constructor(
    private router: Router,
    private usersApi: UsersApiService,
    private navCtrl: NavController,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private modalCtrlr: ModalController
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  onFocusSearch() {
    this.router.navigateByUrl('/search', {
      relativeTo: this.route
    });
  }
  async onInboxTap() {
    this.router.navigateByUrl('inbox', {
      relativeTo: this.route
    });
  }

  async onUserTap() {
    this.usersApi.isLoggedIn().then(loggedIn => {
      if (loggedIn) {
        this.router.navigateByUrl(`me`, {
          relativeTo: this.route
        });
      } else {
        this.showLoginAlert();
      }
    }).catch(err => {
      // error, not sure what to do here but let's show a login alert page
      this.showLoginAlert();
    });
  }

  async showLoginAlert() {
    const modal = await this.modalCtrlr.create({
      cssClass: 'generic-alert-modal',
      component: GenericAlertComponent,
      componentProps: {
        title: this.translate.instant('MODAL_LOGIN.TITLE'),
        message: this.translate.instant('MODAL_LOGIN.SUBTITLE'),
        buttons: [
          {
            title: this.translate.instant('MODAL_LOGIN.BTN_LOGIN'),
            value: 'login'
          }, {
            title: this.translate.instant('MODAL_LOGIN.BTN_CANCEL'),
            value: 'cancel'
          }
        ]
      }
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data && data.value === 'login') {
      return this.navCtrl.navigateRoot(['/login'], { queryParams: { redirectUrl: this.router.url } });
    } else {
      return '';
    }
  }

}
