import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-gallery-modal',
  templateUrl: './gallery-modal.page.html',
  styleUrls: ['./gallery-modal.page.scss'],
})
export class GalleryModalPage implements OnInit {

  @Input() slideImages;
  @Input() i;

  @ViewChild('slideWithNav2') slideWithNav2: IonSlides;
  slideOptsTwo;

  constructor(private modal: ModalController) { }

  ngOnInit() {
    this.slideOptsTwo =  {
      initialSlide: this.i,
      slidesPerView: 1,
      spaceBetween: 20
    };
    this.slideWithNav2.update();
  }

  async closeClicked() {
    return this.modal.dismiss();
  }

 //Method called when slide is changed by drag or navigation
 SlideDidChange(object) {
  this.slideWithNav2.getActiveIndex().then(index => {
    console.log(index);
 });
}

}
