import { CoreAuthLibClientService } from '@app/core-auth-lib/services/core-auth-lib-client.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Itinerary } from '../interfaces/itinerary';
import { ItineraryBuilderUtilsService } from './itinerary-builder-utils.service';
import { ItineraryDay } from '../interfaces/itinerary-day';
import { environment } from '@env/environment';
import moment from 'moment';
import { ItineraryItem } from '../interfaces/itinerary-item';

const service = environment.pwaAPI.base_url;
@Injectable({
  providedIn: 'root'
})
export class ItineraryBuilderItineraryClientService {

  private BASE_URL = environment.itineraryBuilderSDK.base_url;

  constructor(
    private http: HttpClient,
    private coreAuthLibClientService: CoreAuthLibClientService,
    private utils: ItineraryBuilderUtilsService
  ) { }

  async getItineraries() {
    const token = await this.coreAuthLibClientService.getToken();
    const headers = this.utils.getAuthorizationTokenHeaders(token);
    const getItinerariesUrl = `${this.BASE_URL}/api/v1/itineraries`;
    const getItinerariesObservable = this.http.get<Itinerary[]>(getItinerariesUrl, { headers });
    return new Promise<Itinerary[]>((resolve, reject) => {
      getItinerariesObservable.subscribe(result => {
        if (result) {
          resolve(result);
//          resolve(this.processItinerary(result));
        } else {
          reject('Unknow error');
        }
      });
    });
  }

  async getPresetItineraries() {
    const getItinerariesUrl = `${service}?fn=itineraries&domainid=1077`;
    const getItinerariesObservable = this.http.get<Itinerary[]>(getItinerariesUrl);
    return new Promise<Itinerary[]>((resolve, reject) => {
      getItinerariesObservable.subscribe(result => {
        if (result) {
          resolve(result);
//          resolve(this.processItinerary(result));
        } else {
          reject('Unknow error');
        }
      });
    });
  }

  async getPresetItinerariesDays() {
    const getItinerariesUrl = `${service}?fn=itineraryday&domainid=1077`;
    const getItinerariesObservable = this.http.get<ItineraryDay[]>(getItinerariesUrl);
    return new Promise<ItineraryDay[]>((resolve, reject) => {
      getItinerariesObservable.subscribe(result => {
        if (result) {
          resolve(result);
//          resolve(this.processItinerary(result));
        } else {
          reject('Unknow error');
        }
      });
    });
  }

  async getPresetItinerariesDaysItems() {
    const getItinerariesUrl = `${service}?fn=itineraryitems&domainid=1077`;
    const getItinerariesObservable = this.http.get<ItineraryItem[]>(getItinerariesUrl);
    return new Promise<ItineraryItem[]>((resolve, reject) => {
      getItinerariesObservable.subscribe(result => {
        if (result) {
          resolve(result);
//          resolve(this.processItinerary(result));
        } else {
          reject('Unknow error');
        }
      });
    });
  }

  private processItinerary(itineraries: Itinerary[]) {
    itineraries.forEach(itinerary => {
      itinerary.ItineraryDays.forEach(day => {
        day.ItineraryItems.forEach(item => {
          if (item.StartTime !== undefined && item.StartTime !== 'undefined') {
            item.StartTime = `${moment(day.ItineraryDayDate).format('YYYY-MM-D')}T${item.StartTime}`;
          }
          if (item.EndTime !== undefined && item.EndTime !== 'undefined') {
            item.EndTime = `${moment(day.ItineraryDayDate).format('YYYY-MM-D')}T${item.EndTime}`;
          }
        });
      });
    });
    return itineraries;
  }
  /**
   * Call this one if user doens't have its own itienrary
   * @param email User email
   */
  async addItinerary(email: string) {
    const token = await this.coreAuthLibClientService.getToken();
    const headers = this.utils.getAuthorizationTokenHeaders(token);
    const deleteItineraryUrl = `${this.BASE_URL}/api/v1/itineraries`;
    const requestObservable = this.http.post<{
      WasSuccessful: boolean
    }>(deleteItineraryUrl, { Email: email }, { headers });
    return new Promise<{ wasSuccessful: boolean }>((resolve, reject) => {
      requestObservable.subscribe(result => {
        if (result) {
          resolve({ wasSuccessful: result.WasSuccessful });
        } else {
          reject('Unknow error');
        }
      });
    });
  }

  /**
   * To save a preset itienrary copy into users itinerary
   * @param itinerary
   */
  async addPresetItinerary(itinerary: Itinerary) {
    const token = await this.coreAuthLibClientService.getToken();
    const headers = this.utils.getAuthorizationTokenHeaders(token);
    const deleteItineraryUrl = `${this.BASE_URL}/api/v1/itineraries`;
    const requestObservable = this.http.post<{
      WasSuccessful: boolean
    }>(deleteItineraryUrl, { headers });
    return new Promise<{ wasSuccessful: boolean }>((resolve, reject) => {
      requestObservable.subscribe(result => {
        if (result) {
          resolve({ wasSuccessful: result.WasSuccessful });
        } else {
          reject('Unknow error');
        }
      });
    });
  }

  async deleteItinerary(itineraryId: number) {
    const token = await this.coreAuthLibClientService.getToken();
    const headers = this.utils.getAuthorizationTokenHeaders(token);
    const deleteItineraryUrl = `${this.BASE_URL}/api/v1/itineraries/${itineraryId}`;
    const requestObservable = this.http.delete<{
      WasSuccessful: boolean
    }>(deleteItineraryUrl, { headers });
    return new Promise<{ wasSuccessful: boolean }>((resolve, reject) => {
      requestObservable.subscribe(result => {
        if (result) {
          resolve({ wasSuccessful: result.WasSuccessful });
        } else {
          reject('Unknow error');
        }
      });
    });
  }
}
