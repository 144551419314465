import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { canActivate, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

import { NgModule } from '@angular/core';
import { UserLoginGuard } from '@app/core/users-api/user-login.guard';

const redirectUnauthorizedToLogin = redirectUnauthorizedTo(['login']);

const routes: Routes = [
  { path: '', loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: 'onboard', loadChildren: './walkthrough/walkthrough.module#WalkthroughPageModule' },
  { path: 'listings', loadChildren: './listings/listings.module#ListingsPageModule' },
  { path: 'events', loadChildren: './events/events.module#EventsPageModule' },
  { path: 'digital-brochure', loadChildren: './digital-brochure/digital-brochure.module#DigitalBrochurePageModule' },
  { path: 'deals', loadChildren: './deals/deals.module#DealsPageModule' },
  { path: 'help', loadChildren: './help/help.module#HelpPageModule' },
  { path: 'inbox', loadChildren: './inbox/inbox.module#InboxPageModule' },
  { path: 'search', loadChildren: './search/search.module#SearchPageModule' },
  {
    path: 'itineraries',
    canActivate: [ UserLoginGuard ],
//    loadChildren: './itineraries/itineraries.module#ItinerariesPageModule'
loadChildren: './itinerariesv3/itinerariesv3.module#Itinerariesv3PageModule'
},
  { path: 'social-wall', loadChildren: './social-wall/social-wall.module#SocialWallPageModule' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  {
    path: 'me',
    canActivate: [ UserLoginGuard ],
    loadChildren: './me-tnt/me-tnt.module#MeTntPageModule'
  },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'sign-up', loadChildren: './sign-up/sign-up.module#SignUpPageModule' },
  { path: 'forgot-password', loadChildren: './forgot-password/forgot-password.module#ForgotPasswordPageModule' },
  { path: 'connect-with-us', loadChildren: './connect-with-us/connect-with-us.module#ConnectWithUsModule' },
  { path: 'survey', loadChildren: './survey/survey.module#SurveyModule' },
  { path: 'category', loadChildren: './category/category.module#CategoryPageModule' },
  { path: 'regions', loadChildren: './regions/regions.module#RegionsPageModule' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class CodebaseRoutingModule { }
