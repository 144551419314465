import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CMSDataControllerService } from '@app/core/data/controller/cmsdata-controller.service';
import { environment } from '@env/environment';
import { CMSPage } from '@app/core/data/CMSPage';
declare function require(name:string);

@Component({
  selector: 'app-web-view',
  templateUrl: require('template/' + environment.template + '/html/web-view.component.html'),
  styles: [require('template/' + environment.template + '/styles/web-view.component.scss')]
})
export class WebViewComponent implements OnInit {

  public environment = environment;  isLoading: boolean;
  title!: string;
  deepLink?: string;
  page!: CMSPage;

  constructor(
    private route: ActivatedRoute,
    private pagesController: CMSDataControllerService,
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.title = this.route.snapshot.queryParams.t;
    this.deepLink = this.route.snapshot.queryParams.deepLink;

    this.pagesController.getPages({ domainId: environment.cms.domainId } ).subscribe(list => {
      this.isLoading = false;
      this.page = list.find(it => {
        return it.active && it.rssLink && it.rssLink === this.deepLink;
      });
      if (this.page) {
        console.log('Page found');
      } else {
        console.error(`No page found with deeplink[${this.deepLink}]`);
      }
    }, err => {
      console.error(err);
      this.isLoading = false;
    });

  }

}
