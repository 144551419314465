import { ActivatedRoute, ParamMap } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';

@Component({
  selector: 'app-delete-profile',
  templateUrl: './delete-profile.component.html',
  styleUrls: ['./delete-profile.component.scss'],
})
export class DeleteProfileComponent implements OnInit {
  id: string;
  title: string;
  description: string;
  feedbackSent!: boolean;
  options = [
    {value: 'broken', text: 'Something was broken'},
    {value: 'crashes', text: 'App Crashes'},
    {value: 'notifications', text: 'I receive so many notifications'},
    {value: 'privacy', text: 'I have a privacy concern'},
    {value: 'other', text: 'Other'},
  ]
  optionSelected: string;
  constructor(
    private modalCtrlr: ModalController,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.queryParams.id;
    // this.title = this.route.snapshot.queryParams.t;
    this.description = this.route.snapshot.queryParams.d;
  }

  sentPositiveFeedback() {
    // todo: sent feedback to backend when it's ready
    this.feedbackSent = true;
  }

  goBack() {
    this.modalCtrlr.dismiss();
  }

  async continue(){
    this.modalCtrlr.dismiss();
    console.log(this.optionSelected)
    const modal = await this.modalCtrlr.create({
      cssClass: 'ad-alert-modal',
      component:  ConfirmDeleteComponent,
      componentProps: {
        title: 'Delete profile',
        message: 'We are working on improve the experience, we’d be so grateful to be aware your feed back',
        buttons: [
          // {
          //   title: 'Dismiss',
          //   value: 'cancel'
          // }
        ]
      }
    });
    await modal.present().then( result => { } );
    //await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data && data.value === 'cancel') {
      return '';
    }
  }

  sentNegativeFeedback() {
    // todo: sent feedback to backend when it's ready
    this.feedbackSent = true;
  }

}
