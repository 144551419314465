import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private itinerarySrcSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(private storage: Storage) {
    this.init();
  }

  private async init() {
    const initialValue = await this.storage.get('itinerarySrc');
    this.itinerarySrcSubject.next(initialValue);
  }

  setItem(key: string, value: any): Promise<any> {
    if (key === 'itinerarySrc') {
      this.itinerarySrcSubject.next(value);
    }
    return this.storage.set(key, value);
  }

  getItem(key: string): Promise<any> {
    return this.storage.get(key);
  }

  watchItinerarySrc(): Observable<string | null> {
    return this.itinerarySrcSubject.asObservable();
  }
}
