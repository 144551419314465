import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-i-frame-modal',
  templateUrl: './i-frame-modal.page.html',
  styleUrls: ['./i-frame-modal.page.scss'],
})
export class IFrameModalPage implements OnInit {

  @Input() url: string;
  public urlSanitized: SafeResourceUrl;
  constructor(private modal: ModalController,
    public domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.urlSanitized = this.domSanitizer.bypassSecurityTrustResourceUrl(this.url)
  }

  async closeClicked() {
    return this.modal.dismiss();
  }

}
