export const settings = {
  languages: [
    { label: "English", value: 'en' },
    { label: 'Español', value: 'es' },
    { label: 'Français', value: 'fr' },
    { label: 'Deutsch', value: 'de' },
    { label: '中文', value: 'sc' },
    { label: 'हिन्दी', value: 'hi' }
  ],
  site: {
    name: 'Mason County, Washington',
    address: '215 W Railroad Ave, Shelton, WA 98584, United States',
    phone: '+13604262021',
    map: 'https://maps.app.goo.gl/oaMe9ZAKsPxnErbo9',
    latitude: 47.21271,
    longitude: -123.1040777,
    website:'https://www.explorehoodcanal.com/',
    privacyLink:'https://www.explorehoodcanal.com/privacy-policy',
    twitter:'https://twitter.com/MasonCoTourism',
    facebook: 'https://www.facebook.com/ExploreHoodCanal/',
    pinterest:'',
    instagram:'https://www.instagram.com/explorehoodcanal/',
    youtube:'',
    splashImage: '../themes/MasonCounty/assets/splashscreens/bg_img.png',
    homeEvents: false
  },
  debug: true

};
