import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';
declare function require(name:string);

@Component({
  selector: 'app-listing-cell',
  templateUrl: require('template/' + environment.template + '/html/listing-cell.component.html'),
  styles: [require('template/' + environment.template + '/styles/listing-cell.component.scss')]
})
export class ListingCellComponent implements OnInit {

  public environment = environment;
  @Input() public listingTitle?: string;
  @Input() public listingId?: string;
  @Input() public subcategoryTitle?: string;
  @Input() public image?: string;
  @Input() public distance?: number;

  constructor() { }

  ngOnInit() { }

}
