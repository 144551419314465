import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessagesSdkAccessTokenUtilsService {

  constructor() { }

  getAccessTokenHeader(accessToken: { tokenType: string, accessToken: string }) {
    return new HttpHeaders({
      Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`
    });
  }
}
