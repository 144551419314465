import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerUpdateService {

  constructor(private swWorker: SwUpdate, private toast: ToastController) {
    if (!this.swWorker.isEnabled) {
      //console.log('Nope 🙁');
    }
    this.swWorker.available.subscribe(async evt => {
      const updateToast = await this.toast.create({
        message: 'There is a new Toast version available, view will refresh in 5 seconds',
        closeButtonText: 'Refresh Now',
        duration: 5000,
        showCloseButton: true
      });
      updateToast.onDidDismiss().then(() => {
        window.location.reload();
      });
      updateToast.present();
    });
  }
}
