import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chunk'
})
export class ChunkPipe implements PipeTransform {
  transform(array: any[], size: number): any[] {
    if (!array) return [];
    return Array(Math.ceil(array.length / size))
      .fill(null)
      .map((_, index) => array.slice(index * size, (index + 1) * size));
  }
}
