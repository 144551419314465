import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor(private loadingCtrl: LoadingController) { }

  private spinner!: HTMLIonLoadingElement;

  async presentLoading() {
    this.spinner = await this.loadingCtrl.create({
    });
    return this.spinner.present();
  }

  async dismiss() {
    return this.spinner.dismiss();
  }
}
