import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DeleteProfileComponent } from '../delete-profile/delete-profile.component';
import { UsersApiService } from '@app/core/users-api/users-api.service';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss'],
})
export class ConfirmDeleteComponent implements OnInit {
  id: string;
  title: string;
  description: string;
  feedbackSent!: boolean;
  options = [
    {value: 'broken', text: 'Something was broken'},
    {value: 'crashes', text: 'App Crashes'},
    {value: 'notifications', text: 'I receive so many notifications'},
    {value: 'privacy', text: 'I have a privacy concern'},
    {value: 'other', text: 'Other'},
  ]
  optionSelected: string;
  constructor(
    private modalCtrlr: ModalController,
    public usersApi: UsersApiService,
    public router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.queryParams.id;
    this.title = this.route.snapshot.queryParams.t;
    this.description = this.route.snapshot.queryParams.d;
  }

  sentPositiveFeedback() {
    // todo: sent feedback to backend when it's ready
    this.feedbackSent = true;
  }

  goBack() {
    this.modalCtrlr.dismiss();
  }

  async confirm(){
    this.usersApi.deleteUser().then((resp: any) => {
      console.log(resp);
      if (resp.WasSuccessful){
        this.modalCtrlr.dismiss();
        this.router.navigate(['/login']);
      }else{
        this.modalCtrlr.dismiss();
      }
    })
  }

  sentNegativeFeedback() {
    // todo: sent feedback to backend when it's ready
    this.feedbackSent = true;
  }

}
