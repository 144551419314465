import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
// import { CalendarComponent } from 'ionic2-calendar';
import { formatDate } from '@angular/common';
import { environment } from '@env/environment';
declare function require(name:string);

@Component({
  selector: 'app-choose-date',
  templateUrl: require('template/' + environment.template + '/html/choose-date.component.html'),
  styles: [require('template/' + environment.template + '/styles/choose-date.component.scss')]
})
export class ChooseDateComponent implements OnInit {

  public environment = environment;  eventSource = [];
  viewTitle: string;
  calendar = {
    mode: 'month',
    currentDate: new Date(),
  };

  selectedDate: Date;

  // @ViewChild(CalendarComponent) myCal: CalendarComponent;
  constructor(
    private alertCtrl: AlertController,
    @Inject(LOCALE_ID) private locale: string,
    private modalCtrl: ModalController) { }

  ngOnInit() {}
// Change current month/week/day
next() {
  // this.myCal.slideNext();
}

back() {
  // this.myCal.slidePrev();
}

// Selected date reange and hence title changed
onViewTitleChanged(title) {
  this.viewTitle = title;
}

// Calendar event was clicked
async onEventSelected(event) {
  // Use Angular date pipe for conversion
  let start = formatDate(event.startTime, 'medium', this.locale);
  let end = formatDate(event.endTime, 'medium', this.locale);

  const alert = await this.alertCtrl.create({
    header: event.title,
    subHeader: event.desc,
    message: 'From: ' + start + '<br><br>To: ' + end,
    buttons: ['OK'],
  });
  alert.present();
}

createRandomEvents() {
  var events = [];
  for (var i = 0; i < 50; i += 1) {
    var date = new Date();
    var eventType = Math.floor(Math.random() * 2);
    var startDay = Math.floor(Math.random() * 90) - 45;
    var endDay = Math.floor(Math.random() * 2) + startDay;
    var startTime;
    var endTime;
    if (eventType === 0) {
      startTime = new Date(
        Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate() + startDay
        )
      );
      if (endDay === startDay) {
        endDay += 1;
      }
      endTime = new Date(
        Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate() + endDay
        )
      );
      events.push({
        title: 'All Day - ' + i,
        startTime: startTime,
        endTime: endTime,
        allDay: true,
      });
    } else {
      var startMinute = Math.floor(Math.random() * 24 * 60);
      var endMinute = Math.floor(Math.random() * 180) + startMinute;
      startTime = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + startDay,
        0,
        date.getMinutes() + startMinute
      );
      endTime = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + endDay,
        0,
        date.getMinutes() + endMinute
      );
      events.push({
        title: 'Event - ' + i,
        startTime: startTime,
        endTime: endTime,
        allDay: false,
      });
    }
  }
  this.eventSource = events;
}

removeEvents() {
  this.eventSource = [];
}
}
