import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'; // 1
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class CodebaseTranslateService {

  language: any = this.translateService.currentLang; // 2
  constructor(
    private translateService: TranslateService,
    private storage: Storage
  ) {
    // this.language = this.storage.get(`language`);
    this.initLanguage();
  }

  async initLanguage(){
    const lang = await this.storage.get("language");
    console.log(lang)
    this.languageChange(lang || "en")
    // console.log(this.getCurrentLanguage())
  }

  instant(key: string){
    return this.translateService.instant(key)
  }

  languageChange(languageSelected) {
    this.language = languageSelected;
    console.log(languageSelected) // add this
    this.translateService.use(this.language || "en");  // add this
    this.translateService.setDefaultLang(this.language || "en");
    this.saveLanguage(this.language)
    // this.saveLanguage("fr")
  }  // add this

  getLanguage(){
    return this.translateService.currentLang;
  }
  // Save result of API requests
  private saveLanguage(language) {
    this.storage.set(`language`, language);
  }

  // Get cached API result
  private async getCurrentLanguage(): Promise<any> {
    return await this.storage.get(`language`).then(resp => {
      return resp;
    });
  }

}
