import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ItineraryBuilderUtilsService {

  constructor() { }

  getAuthorizationTokenHeaders(token: { tokenType: string, accessToken: string }) {
    const headers = new HttpHeaders({
      Authorization: `${token.tokenType} ${token.accessToken}`
    });
    return headers;
  }
}
