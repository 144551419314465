import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LikedListingsComponent } from '@app/codebase/itineraries/liked-listings/liked-listings.component';
import { CMSEvent } from '@app/core/data/CMSEvent';
import { Listing } from '@app/core/data/Listing';
import { CMSDataControllerService } from '@app/core/data/controller/cmsdata-controller.service';
import { FavoritesService } from '@app/core/services/favorites.service';
import { Itinerary } from '@app/itinerary-builder-sdk/interfaces/itinerary';
import { ItineraryDay } from '@app/itinerary-builder-sdk/interfaces/itinerary-day';
import { ItineraryItem } from '@app/itinerary-builder-sdk/interfaces/itinerary-item';
import { ItineraryBuilderDayClientService } from '@app/itinerary-builder-sdk/services/itinerary-builder-day-client.service';
import { ItineraryBuilderItineraryClientService } from '@app/itinerary-builder-sdk/services/itinerary-builder-itinerary-client.service';
import { LoadingService } from '@app/shared/services/loading.service';
import { environment } from '@env/environment';
import { ModalController } from '@ionic/angular';
import { FavoriteListingsComponent } from '../favorite-listings/favorite-listings.component';
import { ItineraryBuilderItemClientService } from '@app/itinerary-builder-sdk/services/itinerary-builder-item-client.service';
declare function require(name:string);

@Component({
  selector: 'app-create-itinerary',
  templateUrl: require('template/' + environment.template + '/html/create-itinerary.component.html'),
  styles: [require('template/' + environment.template + '/styles/create-itinerary.component.scss')]
})
export class CreateItineraryComponent implements OnInit {

  public environment = environment;  @Input() title: string;
  @Input() public day: ItineraryDay;
  public stops!: ItineraryItem[];
  @Input() public itinerary?: Itinerary;
  public itineraryDays: any[] = [];
  @ViewChild('datePicker') datePicker: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private loading: LoadingService,
    private itineraryBuilderItineraryClient: ItineraryBuilderItineraryClientService,
    private itineraryBuilderDayClientService: ItineraryBuilderDayClientService,
    private itineraryBuilder: ItineraryBuilderItemClientService,
    private modalController: ModalController,
    private dataController: CMSDataControllerService,
    private favoritesService: FavoritesService,
  ) {
    this.title = this.route.snapshot.queryParams.t;
  }

  ngOnInit() {
    this.loadItinerary();
  }

  goBack(){
    window.history.back()
  }

  public async onNewDayDateChanged(date: any) {
    await this.loading.presentLoading();
    const year: string = date.year.text;
    const month: string = date.month.value < 10 ? '0' + date.month.value.toString(): date.month.value.toString();
    const day: string = date.day.text;
    const dateString = year + '/' + month + '/' + day;
    const dateObject = new Date(dateString);
    dateObject.setHours(0, 0, 0, 0);
    const isoString = dateObject.toISOString();
    await this.addNewDay(isoString);
    this.datePicker = null;
    return this.loading.dismiss();
  }

  async addNewDay(isoDate: string) {
    const itineraryId = this.route.snapshot.params.id;
    await this.itineraryBuilderDayClientService.addDay(parseInt(itineraryId, 10), isoDate);
    await this.loadItinerary();
  }

  async onAddElementButtonClick() {
    const modal = await this.modalController.create({
      component: FavoriteListingsComponent,
      componentProps: {
        // itineraryId: this.route.snapshot.params.id,
        // dayId: this.day.Id,
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    //console.log(data)
    if (data && data.updated && data.itineraryItem) {
      this.stops.push(data.itineraryItem);
    } else {
      //console.log('>>> Nothing changed');
    }
  }

  async onAddPresetItineraryTap() {
    await this.loading.presentLoading();
    await this.itineraryBuilderItineraryClient.addPresetItinerary(this.itinerary);
    return this.loading.dismiss();
  }
  private createDefaultDay() {
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    const isoString = todayDate.toISOString();
    this.addNewDay(isoString);
  }

  async loadItinerary() {
    this.itinerary = (await this.itineraryBuilderItineraryClient.getPresetItineraries()).find(obj => obj.Id == this.route.snapshot.queryParams.itineraryId)
    this.itineraryDays = (await this.itineraryBuilderItineraryClient.getPresetItinerariesDays()).filter(it => it.ItineraryId == this.route.snapshot.queryParams.itineraryId);
  }

  async onItemClick() {
    //console.log(item)
    await this.loading.presentLoading();
    let promises: any[] = [];
    let itemsDay = this.itineraryDays//.filter((obj) => obj.ItineraryID == this.day.Id);
    let userItinerary = (
      await this.itineraryBuilderItineraryClient.getItineraries()
    )[0];
    this.itineraryDays.forEach(async (day) => {
      const rest = await this.itineraryBuilderDayClientService.addDayFromPreset(
        userItinerary.Id,
        day.ItineraryDate
      );
      day.items = await (
        await this.itineraryBuilderItineraryClient.getPresetItinerariesDaysItems()
      ).filter((item) => item.DayId == day.Id);
      day.items.forEach(ite => {
        promises.push(
          this.itineraryBuilder.addItemToDay(
            +userItinerary.Id,
            rest.dayId,
            "" + ite.ListingId,
            "" + ite.EventId,
            ite.StartTime,
            ite.EndTime
          )
        );
      })
    });

    await Promise.all(promises);
    this.loading.dismiss();
  }
}
