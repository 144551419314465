import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';

import { ModalController } from '@ionic/angular';
declare function require(name:string);

@Component({
  selector: 'app-generic-alert',
  templateUrl: require('template/' + environment.template + '/html/generic-alert.component.html'),
  styles: [require('template/' + environment.template + '/styles/generic-alert.component.scss')]
})
export class GenericAlertComponent implements OnInit {

  public environment = environment;
  @Input() title?: string;
  @Input() message?: string;
  @Input() buttons?: { title: string, value?: string }[];

  constructor(private modal: ModalController) { }

  ngOnInit() { }

  async onButtonClick(evt: any) {
    return this.modal.dismiss({
      value: evt
    });
  }
}
