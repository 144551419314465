import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { CodebaseRoutingModule } from './codebase.router.module';
import { CommonModule } from '@angular/common';
import { CoreComponentModule } from '@app/core/core.module';
import { Facebook } from '@ionic-native/facebook/ngx';
import { Firebase } from '@ionic-native/firebase/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { NgModule } from '@angular/core';
import { RemoteNotificationsService } from '@app/core/services/remote-notifications.service';
import { SharedComponentModule } from '@app/shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        CodebaseRoutingModule,
        CoreComponentModule,
        SharedComponentModule,
        AngularFireMessagingModule
    ],
    providers: [
        RemoteNotificationsService,
        Firebase,
        Facebook,
        GooglePlus
    ]
})
export class CodebaseModule { }
