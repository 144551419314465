import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Venues } from '@app/core/data/BandWango';
import { CMSPage } from '@app/core/data/CMSPage';
import { CMSDataControllerService } from '@app/core/data/controller/cmsdata-controller.service';
import { Deal } from '@app/core/data/Deal';
import { IFrameModalPage } from '@app/i-frame-modal/i-frame-modal.page';
import { environment } from '@env/environment';
import { ModalController } from '@ionic/angular';
import { SafePipe } from '../SafePipe';

@Component({
  selector: 'app-venues',
  templateUrl: './venues.page.html',
  styleUrls: ['./venues.page.scss'],
  providers:[SafePipe]
})
export class VenuesPage implements OnInit {

  public environment = environment;
  deal!: Deal;
  bandwangos: any[];
  venues: Venues[];
  constructor(
    private safe: SafePipe,
    private router: Router,
    private modalCtrlr: ModalController,
    public route: ActivatedRoute,
    private dataController: CMSDataControllerService
  ) { }

  ngOnInit() {
    const id = this.route.snapshot.params.id;
    console.log(id)
    this.dataController
    .getBandwangos()
    .subscribe(str => {
      let obj = JSON.parse(str).deals;
      let objVenues = JSON.parse(str).venues;

      console.log(obj);
      this.bandwangos = Object.values(obj);
      this.bandwangos = this.bandwangos.filter(obj => obj.id == id).map(item => ({
        ...item,
        showMore:false,
      }));
      this.venues = Object.values(objVenues);
      console.log(this.venues);
      console.log(this.bandwangos[0]);
      this.venues = this.venues.filter(obj => this.bandwangos[0].venue_ids.includes(obj.id));
      // this.venues = objVenues
      console.log(this.venues);
    }, err => {
      console.error(err);
    });
  }

  goBack(){
    window.history.back()
  }

  async passportClick(item: any) {
    window.open(item.checkoutUrl, '_blank');
  }

  dealClick(item: any){
    this.router.navigate(['deals/', item.id]);
  }

}
