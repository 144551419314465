import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
declare function require(name:string);

@Component({
  selector: 'app-shared',
  templateUrl: require('template/' + environment.template + '/html/shared.component.html'),
  styles: [require('template/' + environment.template + '/styles/shared.component.scss')]
})
export class SharedComponent implements OnInit {

  public environment = environment;
  constructor() { }

  ngOnInit() {}

}
