import { Component, OnInit } from '@angular/core';

import { CMSDataControllerService } from '@app/core/data/controller/cmsdata-controller.service';
import { CMSPage } from '@app/core/data/CMSPage';
import { DeepLinkHelper } from '@app/core/data/utils/DeepLinkHelper';
import { ModalController } from '@ionic/angular';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
declare function require(name:string);

@Component({
  selector: 'app-select-avatar',
  templateUrl: require('template/' + environment.template + '/html/select-avatar.page.html'),
  styles: [require('template/' + environment.template + '/styles/select-avatar.page.scss')]

})
export class SelectAvatarPage implements OnInit {

  public environment = environment;
  pages!: CMSPage[];
  public selectedAvatar!: CMSPage;
  language: string;

  constructor(
    private pagesController: CMSDataControllerService,
    public translate: TranslateService,
    private modal: ModalController) {
  }

  ngOnInit() {
    this.language = this.translate.currentLang;
    this.pagesController.getPages({ domainId: environment.cms.domainId }, this.language).subscribe(list => {
      const parentPage = list.find(it => {
        return it.active && it.rssLink && it.rssLink === new DeepLinkHelper().getAvatarRssLink();
      });
      if (parentPage) {
        this.pages = list.filter(item => {
          return item.active && parentPage.PageID === item.ParentPageID;
        }).sort((a, b) => {
          if (a.SortOrder > b.SortOrder) {
            return 1;
          } else if (a.SortOrder < b.SortOrder) {
            return -1;
          }
          return 0;
        });
      }
    });
  }

  onAvatarClick(avatar: CMSPage) {
    this.selectedAvatar = avatar;
  }

  async onButtonClick(evt: any) {
    if (this.selectedAvatar) {
      return this.modal.dismiss({
        value: this.selectedAvatar.ButtonImage,
      });
    } else {
      return this.modal.dismiss();
    }
  }
}
