import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { ModalController } from '@ionic/angular';
import { GamClient } from "@simpleview/dtn-client";
declare function require(name:string);

@Component({
  selector: 'app-ad-alert',
  templateUrl: require('template/' + environment.template + '/html/ad-alert.component.html'),
  styles: [require('template/' + environment.template + '/styles/ad-alert.component.scss')]
})
export class AdAlertComponent implements OnInit {

  public environment = environment;
  @Input() title?: string;
  @Input() message?: string;
  @Input() buttons?: { title: string, value?: string }[];
  dtn = new GamClient();

  constructor(private modal: ModalController) { }

  ngOnInit() { }

  ngAfterViewInit(){
    this.dtn.renderAds();
  }

  async onButtonClick(evt: any) {
    return this.modal.dismiss({
      value: evt
    });
  }
}
