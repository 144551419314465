import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';

import { GenericAlertComponent } from '../generic-alert/generic-alert.component';
import { Injectable } from '@angular/core';
import { UsersApiService } from '@app/core/users-api/users-api.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  constructor(
    private navCtrl: NavController,
    private modalCtrlr: ModalController,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private usersApi: UsersApiService,
    private router: Router) { }

  goHomeOrRedirect(aReidrectUrl?: string) {
    if (!aReidrectUrl) {
      return this.navCtrl.navigateRoot('');
    } else {
      return this.router.navigateByUrl(aReidrectUrl);
    }
  }

  async onRestrictedUrlAccessTry(url: string, queryParams: Params, isItinerary?: boolean) {
    console.log(isItinerary)
    this.usersApi.isLoggedIn().then(loggedIn => {
      if (loggedIn) {
        this.router.navigate([url], {
          queryParams,
          relativeTo: this.route
        });
      } else {
        this.showLoginAlert(isItinerary);
      }
    }).catch(err => {
      // error, not sure what to do here but let's show a login alert page
      this.showLoginAlert(isItinerary);
    });
  }

  async showLoginAlert(itinerary?: boolean) {
    const modal = await this.modalCtrlr.create({
      cssClass: 'my-custom-class',
      component: GenericAlertComponent,
      componentProps: {
        title: !itinerary ? this.translate.instant('MODAL_LOGIN.TITLE') : this.translate.instant('MODAL_LOGIN.TITLEITINERARY'),
        message: this.translate.instant('MODAL_LOGIN.SUBTITLE'),
        buttons: [
          {
            title: this.translate.instant('MODAL_LOGIN.BTN_LOGIN'),
            value: 'login'
          }, {
            title: this.translate.instant('MODAL_LOGIN.BTN_CANCEL'),
            value: 'cancel'
          }
        ]
      }
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data && data.value === 'login') {
      return this.router.navigate(['/login'], { queryParams: { redirectUrl: this.router.url } });
    } else {
      return '';
    }
  }

}
