import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class SecureSessionStorerService {

  public SESSION_TYPE_APPLICATION = 'application';
  public SESSION_TYPE_USER = 'user';
  private sessionTypeKey = 'CoreAuthLibSDKSessionTypeKey';
  private accessTokenKey = 'CoreAuthLibSDKAccessTokenKey';
  private tokenTypeKey = 'CoreAuthLibSDKTokenTypeKey';
  private refreshTokenKey = 'CoreAuthLibSDKRefreshTokenKey';

  private expiresAtKey = 'CoreAuthLibSDKExpiresAtKey';
  private issuedAtKey = 'CoreAuthLibSDKIssuedAtKey';

  constructor(private storage: Storage) { }

  /**
   * There are only two valid values 'application' or 'user', call it after log in
   */
  async setSessionType(type: string) {
    try {
      await this.storage.set(this.sessionTypeKey, type);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Return 'application' or 'user'
   */
  async sessionType(): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      this.storage.get(this.sessionTypeKey).then(value => {
        resolve(value);
      }).catch(async err => {
        await this.storage.set(this.sessionTypeKey, this.SESSION_TYPE_APPLICATION);
        resolve(this.SESSION_TYPE_APPLICATION);
      });
    });
  }

  async deleteSession() {
    await this.storage.remove(this.accessTokenKey);
    await this.storage.remove(this.tokenTypeKey);
    await this.storage.remove(this.refreshTokenKey);
    await this.storage.remove(this.expiresAtKey);
    await this.storage.remove(this.issuedAtKey);
  }

  async storeSession(session: {
    access_token: string;
    token_type: string;
    refresh_token: string;
    expires_at: string;
    issued_at: string;
  }) {
    try {
      await this.storage.set(this.accessTokenKey, session.access_token);
      await this.storage.set(this.tokenTypeKey, session.token_type);
      await this.storage.set(this.refreshTokenKey, session.refresh_token);
      await this.storage.set(this.expiresAtKey, session.expires_at);
      await this.storage.set(this.issuedAtKey, session.issued_at);
      return true;
    } catch (error) {
      throw error;
    }
  }

  async session() {
    try {
      const accessToken = await this.storage.get(this.accessTokenKey);
      // console.log('SecureSessionStorerService - Recovered Access Token');
      const tokenType = await this.storage.get(this.tokenTypeKey);
      // console.log('SecureSessionStorerService - Recovered Token Type');
      const refreshToken = await this.storage.get(this.refreshTokenKey);
      // console.log('SecureSessionStorerService - Recovered Refresh Token');
      const expiresAt = await this.storage.get(this.expiresAtKey);
      // console.log('SecureSessionStorerService - Recovered Expires At');
      const issuedAt = await this.storage.get(this.issuedAtKey);
      // console.log('SecureSessionStorerService - Recovered Issued At');
      const accessTokenResponse: {
        accessToken: string;
        tokenType: string;
        refreshToken: string;
        expiresAt: string;
        issuedAt: string;
      } = { accessToken, tokenType, refreshToken, issuedAt, expiresAt };
      return accessTokenResponse;
    } catch (error) {
      console.log('SecureSessionStorerService - session()');
      console.log('error');
      throw error;
    }
  }

}
