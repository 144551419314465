import { Component } from "@angular/core";
import { Platform, ToastController } from "@ionic/angular";
import { Router } from "@angular/router";
import { ServiceWorkerUpdateService } from "./core/services/service-worker-update.service";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Storage } from "@ionic/storage";
import { NetworkService } from "./services/network.service";
import { TokenControllerService } from "./messages-sdk/services/token-controller.service";
import { TranslateService } from "@ngx-translate/core";
import { CodebaseTranslateService } from "./services/codebase-translate.service";
import { ChannelsClientService } from "./messages-sdk/services/channels-client.service";
import { Push, PushObject, PushOptions } from "@ionic-native/push/ngx";
import { environment } from "@env/environment";
import { WeatherApiService } from "./shared/weather-api/weather-api.service";
import moment from "moment";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { StorageService } from "./services/storage.service";
declare function require(name: string);

@Component({
  selector: "app-root",
  templateUrl: require("template/" +
    environment.template +
    "/html/app.component.html"),
})
export class AppComponent {
  public showSplash: boolean = true;
  public splashImage: string;
  weather: any;
  public mainImage: any;
  public environment = environment;
  bodyStyles = document.body.style;
  deferredPrompt: any;
  public IBURL :SafeResourceUrl;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private update: ServiceWorkerUpdateService,
    private router: Router,
    private storage: Storage,
    private push: Push,
    private toastController: ToastController,
    private tokenController: TokenControllerService,
    private networkService: NetworkService,
    private translate: TranslateService,
    private storageService: StorageService,
    private channelService: ChannelsClientService,
    private weatherApiService: WeatherApiService,
    private codebaseTranslateService: CodebaseTranslateService,
    private sanitizer: DomSanitizer
  ) {
    this.initializeApp();
    // storage.get('language').then(lang => {
    // })
  }

  ngOnInit() {
    this.storageService.watchItinerarySrc().subscribe((newValue) => {
      if (newValue) {
        console.log('itinerarySrc actualizado:', newValue);
        this.IBURL = this.sanitizer.bypassSecurityTrustResourceUrl(newValue); // Realiza la lógica necesaria
      }
    });
  }

  async initializeApp() {
    this.IBURL = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.ItineraryBuilder.base_url}`);
    this.weatherApiService
      .getWeather({ zipCode: environment.cms.postalCode })
      .then((weather) => {
        this.weather = {
          icon: this.getIconWeather(weather.Forecast.Today.Description),
          current: `${weather.Forecast.Today.Current}°`,
          date: moment().format('dddd, MMMM D, YYYY')
        }

        // this.codebaseTranslateService.languageChange("eng");
        var timer = 0;
        var interval = setInterval(() => {
          timer++;
          if (timer < 7) {
          } else {
            clearInterval(interval);
            this.showSplash = false;
            //console.log('Done');
          }
        }, 1000);
      })
      .catch((err) => {
        console.error(err);
        this.weather = ".";

        // this.codebaseTranslateService.languageChange("eng");
        var timer = 0;
        var interval = setInterval(() => {
          timer++;
          if (timer < 7) {
          } else {
            clearInterval(interval);
            this.showSplash = false;
            //console.log('Done');
          }
        }, 1000);
      });

      this.mainImage = `/themes/${environment.theme.name}/assets/splash/background_splash.png`;
    this.bodyStyles.setProperty(
      "--image",
      `url("/themes/${environment.theme.name}/assets/splash/background_splash.png")`
    );
    this.bodyStyles.setProperty(
      "--logo",
      `url("/themes/${environment.theme.name}/assets/splash/logo_splash.png")`
    );
    this.splashImage = `../../../themes/${environment.theme.name}/assets/login/logIn_background.png`;
    // this.codebaseTranslateService.languageChange('eng')
    // this.codebaseTranslateService.initLanguage();
    if (this.networkService.getCurrentNetworkStatus() < 1) {
      const toast = await this.toastController.create({
        message: "This app is offline",
        duration: 2000,
        buttons: [
          {
            text: "Close",
            handler: () => {},
          },
        ],
      });
      toast.present();
    }

    if (this.platform.is("android")) {
      var token = await this.storage.get("pushToken");
      if (!token) this.requestPermission();
    } else if (this.platform.is("ios")) {
      var token = await this.storage.get("pushToken");
      if (!token) this.requestNotificationPermission;
    }
  }

  getIconWeather(state: string): string {
    switch (state.toLowerCase()) {
        case 'clear':
        case 'sunny':
            return '☀️';
        case 'night clear':
            return '🌙';
        case 'partly cloudy':
        case 'scattered clouds':
            return '🌤️';
        case 'clouds':
        case 'broken clouds':
        case 'few clouds':
          return '☁️';
        case 'overcast':
        case 'overcast clouds':
            return '🌥️';
        case 'rain':
        case 'shower rain':
        case 'light rain':
            return '🌧️';
        case 'heavy rain':
            return '🌧️';
        case 'storm':
        case 'thunderstorm':
        case 'light thunderstorm':
        case 'heavy thunderstorm':
        case 'thunderstorm with rain':
            return '⛈️';
        case 'drizzle':
        case 'light drizzle':
            return '🌦️';
        case 'snow':
        case 'light snow':
        case 'heavy snow':
            return '❄️';
        case 'sleet':
            return '🌨️';
        case 'mist':
        case 'haze':
            return '🌫️';
        case 'wind':
        case 'breeze':
        case 'strong breeze':
        case 'gale':
        case 'strong gale':
            return '🌬️';
        case 'fog':
            return '🌁';
        case 'smoke':
            return '🔥';
        case 'sand':
        case 'dust':
            return '🌪️';
        case 'tornado':
            return '🌪️';
        case 'hurricane':
        case 'cyclone':
        case 'typhoon':
            return '🌀';
        case 'cold':
            return '🥶';
        case 'hot':
            return '🥵';
        default:
            return '🌤️';
    }
}


  requestNotificationPermission() {
    const options: PushOptions = {
      android: {},
      ios: {
        alert: "true",
        badge: true,
        sound: "false",
      },
    };

    const pushObject: PushObject = this.push.init(options);

    pushObject.on("registration").subscribe(async (registration: any) => {
      // console.log("Device registered", registration);
      await this.storage.set("pushToken", registration);
      await this.tokenController.registerDeviceTokenIos(registration);
      await this.channelService.postSubscribeToChannels(
        ["GlobalNotifications"],
        registration
      );
      // Aquí puedes enviar el token de registro a tu servidor para enviar notificaciones.
    });

    pushObject.on("notification").subscribe((notification: any) => {
      // console.log("Received a notification", notification);
      // Aquí puedes manejar la notificación cuando se recibe.
    });

    pushObject.on("error").subscribe((error: any) => {
      console.error("Error with Push plugin", error);
    });
  }

  requestPermission() {
    this.tokenController.requestPermission().subscribe(
      async (token) => {
        await this.storage.set("pushToken", token);
        await this.tokenController.registerDeviceToken(token);
        await this.channelService.postSubscribeToChannels(
          ["GlobalNotifications"],
          token
        );
      },
      async (err) => {
        console.log(err);
      }
    );
  }
}
