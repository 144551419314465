import { Component, Input, OnInit } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { Events } from '@ionic/angular';
import { CMSPage } from '@app/core/data/CMSPage';
import { CMSDataControllerService } from '@app/core/data/controller/cmsdata-controller.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-tabs-home-widget',
  templateUrl: './tabs-home-widget.component.html',
  styleUrls: ['./tabs-home-widget.component.scss'],
})
export class TabsHomeWidgetComponent implements OnInit {
  @Input() parentPage: CMSPage;
  pageOptions: CMSPage[];
  tabSelected: CMSPage;
  showList!: boolean;
  constructor(
    public events: Events,
    private dataController: CMSDataControllerService,) { }

  ngOnInit() {
    this.dataController.getPages({domainId: environment.cms.domainId, parentPageId: this.parentPage.PageID}).subscribe(pages => {
      this.pageOptions = pages;
      this.tabSelected = pages[0];
    })
    console.log(this.pageOptions)
  }

  segmentChanged(ev: any) {
    AlertService.log(ev)
    // this.showList = JSON.parse(ev.detail.value);
    this.tabSelected = ev;
    this.events.publish("testevent", { listings: [] });
  }
}
