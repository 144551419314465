import { Component, OnInit, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
declare function require(name:string);

@Component({
  selector: 'app-complete-your-profile',
  templateUrl: require('template/' + environment.template + '/html/complete-your-profile.component.html'),
  styles: [require('template/' + environment.template + '/styles/complete-your-profile.component.scss')]
})
export class CompleteYourProfileComponent implements OnInit {

  public environment = environment;  @Input()
  colorClass?: string;

  constructor(
    private router: Router,
    public modalController: ModalController
    ) { }

  ngOnInit() { }

  async onProfileClick() {
    await this.modalController.dismiss();
    this.router.navigate(['/me/edit']);
  }

  async dismiss() {
    return await this.modalController.dismiss();
  }

}
