import { Component, OnInit, Input } from '@angular/core';
import { environment } from '@env/environment';
declare function require(name:string);

@Component({
  selector: 'app-empty-state',
  templateUrl: require('template/' + environment.template + '/html/empty-state.component.html'),
  styles: [require('template/' + environment.template + '/styles/empty-state.component.scss')]
})
export class EmptyStateComponent implements OnInit {

  public environment = environment;  @Input()
  message!: string;

  constructor() { }

  ngOnInit() {}

}
