import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { MessagesSdkSessionStorerService } from './messages-sdk-session-storer.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AccessTokenClientService {

  private accessToken?: {
    accessToken: string;
    tokenType: string;
    refreshToken: string;
    expiresAt: string;
    issuedAt: string;
  };
  private GRANT_TYPE_APPLICATION = 'application';
  private GRANT_TYPE_REFRESH_TOKEN = 'refresh_token';
  private BASE_URL = environment.messagesSDK.base_url;

  constructor(
    private sessionStorer: MessagesSdkSessionStorerService,
    private http: HttpClient
  ) {
    this.getToken();
  }

  async getToken() {
    //if (this.accessToken) { // Tal vez se tiene que cambiar a null
      try {
        this.accessToken = await this.sessionStorer.session();
        //if (this.expired(this.accessToken)) {
          await this.getApplicationToken();
          return this.accessToken;
        //}
        return this.accessToken; // Session not expired
      } catch (error) {
        return await this.getApplicationToken();
      }
    /*} else {
      if (this.expired(this.accessToken)) {
        return await this.refreshToken();
      }
      return this.accessToken;
    }*/
  }

  async getApplicationToken() {
    const url = `${this.BASE_URL}/api/v3/oauth2/token`;
    return new Promise<{
      accessToken: string;
      tokenType: string;
      refreshToken: string;
      expiresAt: string;
      issuedAt: string;
    }>((resolve, reject) => {
      this.http.post<{
        access_token: string;
        token_type: string;
        refresh_token: string;
        expires_at: string;
        issued_at: string;
      }>(
        url,
        this.getApplicationTokenParams(),
        { headers: this.getApplicationTokenHeaders() }
      ).subscribe(async response => {
        if (response) {
          await this.sessionStorer.storeSession(response);
          this.accessToken = await this.sessionStorer.session();
          resolve(this.accessToken);
        } else {
          reject('cauthlib[apptoken]: Unknown error');
        }
      }, err => {
        reject(err);
      });
    });
  }

  async refreshToken() {
    const url = `${this.BASE_URL}/api/v3/oauth2/token`;
    return new Promise<{
      accessToken: string;
      tokenType: string;
      refreshToken: string;
      expiresAt: string;
      issuedAt: string;
    }>((resolve, reject) => {
      this.http.post<{
        access_token: string;
        token_type: string;
        refresh_token: string;
        expires_at: string;
        issued_at: string;
      }>(
        url, // URL
        this.getRefreshTokenParams(this.accessToken.refreshToken), // Body Params
        { headers: this.getRefreshTokenHeaders() } // Headers
      ).subscribe(async response => {
        if (response) {
          this.sessionStorer.storeSession(response);
          this.accessToken = await this.sessionStorer.session();
          resolve(this.accessToken);
        } else {
          await this.getApplicationToken();
          this.accessToken = await this.sessionStorer.session();
          resolve(this.accessToken);
        }
      }, err => {
        reject(err);
      });
    });
  }

  private expired(accessToken: {
    accessToken: string;
    tokenType: string;
    refreshToken: string;
    expiresAt: string;
    issuedAt: string;
  }) {
    const nowDate = new Date();
    const expirationDate = new Date(accessToken.expiresAt);
    console.log('Expiration date ');
    console.log(expirationDate);
    if (expirationDate < nowDate) { // Checar si crra fecha valida
      return true;
    } else {
      return false;
    }
  }


  private getApplicationTokenParams() {
    const params = new HttpParams({
      fromObject: {
        grant_type: this.GRANT_TYPE_APPLICATION,
        client_id: String(environment.messagesSDK.client_id),
        client_secret: environment.messagesSDK.client_secret
      }
    });
    return params;
  }

  private getApplicationTokenHeaders() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return headers;
  }

  private getRefreshTokenParams(refreshToken: string) {
    const params = new HttpParams({
      fromObject: {
        grant_type: this.GRANT_TYPE_REFRESH_TOKEN,
        refresh_token: refreshToken
      }
    });
    return params;
  }

  private getRefreshTokenHeaders() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return headers;
  }
}
