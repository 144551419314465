import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WeatherEntity } from './WeatherEntity';

@Injectable({
  providedIn: 'root'
})
export class WeatherApiService {
  private BASE_URL = 'https://odata.mobimanage.com/api/Weather/';

  constructor(
    private http: HttpClient
    ) { }

  async getWeather(params: {zipCode: any}): Promise<WeatherEntity> {
    return new Promise<WeatherEntity>((resolve, reject) => {
      this.http.get<WeatherEntity>(this.BASE_URL + params.zipCode)
      .subscribe(
        response => {
          resolve(response);
        },
        err => { reject(err); }
      );
    });
  }
}
