import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class MessagesSdkSessionStorerService {

  private accessTokenKey = 'MessagesSDKAccessTokenKey';
  private tokenTypeKey = 'MessagesSDKTokenTypeKey';
  private refreshTokenKey = 'MessagesSDKRefreshTokenKey';

  private expiresAtKey = 'MessagesSDKExpiresAtKey';
  private issuedAtKey = 'MessagesSDKIssuedAtKey';

  constructor(private storage: Storage) { }

  async storeSession(session: {
    access_token: string;
    token_type: string;
    refresh_token: string;
    expires_at: string;
    issued_at: string;
  }) {
    try {
      await this.storage.set(this.accessTokenKey, session.access_token);
      await this.storage.set(this.tokenTypeKey, session.token_type);
      await this.storage.set(this.refreshTokenKey, session.refresh_token);
      await this.storage.set(this.expiresAtKey, session.expires_at);
      await this.storage.set(this.issuedAtKey, session.issued_at);
      return true;
    } catch (error) {
      throw error;
    }
  }

  async session() {
    try {
      const accessToken = await this.storage.get(this.accessTokenKey);
      // console.log('MessagesSdkSessionStorerService - Recovered Access Token');
      const tokenType = await this.storage.get(this.tokenTypeKey);
      // console.log('MessagesSdkSessionStorerService - Recovered Token Type');
      const refreshToken = await this.storage.get(this.refreshTokenKey);
      // console.log('MessagesSdkSessionStorerService - Recovered Refresh Token');
      const expiresAt = await this.storage.get(this.expiresAtKey);
      // console.log('MessagesSdkSessionStorerService - Recovered Expires At');
      const issuedAt = await this.storage.get(this.issuedAtKey);
      // console.log('MessagesSdkSessionStorerService - Recovered Issued At');
      const accessTokenResponse: {
        accessToken: string;
        tokenType: string;
        refreshToken: string;
        expiresAt: string;
        issuedAt: string;
      } = { accessToken, tokenType, refreshToken, issuedAt, expiresAt };
      return accessTokenResponse;
    } catch (error) {
      // console.log('MessagesSdkSessionStorerService - session()');
      // console.log('error');
      throw error;
    }
  }
}
