import { Component, OnInit } from '@angular/core';
import { CMSPage } from '@app/core/data/CMSPage';
import { CMSDataControllerService } from '@app/core/data/controller/cmsdata-controller.service';
import { DeepLinkHelper } from '@app/core/data/utils/DeepLinkHelper';
import { environment } from '@env/environment';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Router, ActivatedRoute } from '@angular/router';
import { RedirectService } from '@app/shared/services/redirect.service';

@Component({
  selector: 'app-trails-tab',
  templateUrl: './trails-tab.component.html',
  styleUrls: ['./trails-tab.component.scss'],
})
export class TrailsTabComponent implements OnInit {
  trails: CMSPage[];
  constructor(
    private dataController: CMSDataControllerService,
    private redirect: RedirectService,
    private route: ActivatedRoute,
    private router: Router,
    private iab: InAppBrowser) { }

  ngOnInit() {
    this.dataController.getPages({domainId: environment.cms.domainId}).subscribe(pages => {
      const parentPage = pages.find(page => page.active && page.rssLink === new DeepLinkHelper().getSeeAndDoRssLink());
      if (parentPage) {
        this.trails = pages.filter(item => {
          return item.active && parentPage.PageID === item.ParentPageID
            && new DeepLinkHelper().getLogoutRssLink() !== item.rssLink;
        }).sort((a, b) => {
          if (a.SortOrder > b.SortOrder) {
            return 1;
          } else if (a.SortOrder < b.SortOrder) {
            return -1;
          }
          return 0;
        });
      }
    })
  }

  onItemClick(item: CMSPage) {
    const title = item.PageName || item.name;
    let link = item.rssLink || item.linkTo;
    if (link.includes("http")){
      this.iab.create(link,  "_self", "popup=yes, toolbarposition=top,closebuttoncaption=Close");
      // this.ticketsClick(link)
    }else if(link.includes("trails://")){
      this.router.navigate(['/tabs/trails']);
    }else if(link.includes("events://")){
      this.router.navigate(['/tabs/events'], { queryParams: { t: title, rssLink: link, parentPageId: item.PageID } });
    }else if(link=="category://?categoryid=map"){
      this.router.navigate(['/tabs/location']);
    }else if(link=="passport://"){
      this.router.navigate(['/tabs/passport']);
    }else if(link=="itinerarybuilder://"){
      this.redirect.onRestrictedUrlAccessTry('/tabs/itineraries', {
        t: item.PageName
      }, true);
    }else if(link=="socialwall://"){
        this.router.navigate(['/tabs/social-wall'], { queryParams: { t: item.PageName } });
    }else if (item.PageName =="Digital Brochure") {
      this.router.navigate(['/tabs/digital-brochure'])
    }else if (item.PageName =="Itinerary Builder") {
      this.router.navigateByUrl('itineraries', {
        relativeTo: this.route,
        queryParams: { t: 'Itineraries' }
      });
    }else if (item.PageName =="All in One Ticket") {
      this.router.navigate(['/passport'])
    }else{
      switch (link.split("?")[0]) {
        case 'dine://':
          this.router.navigate(['/tabs/stay'], { queryParams: { t: title, parentPageId: item.PageID } });
          break;
        case 'map://':
          this.router.navigate(['/tabs/listings'], { queryParams: { t: title, showMap: true, parentPageId: item.PageID} });
          break;
        case 'content://':
          this.router.navigate(['/tabs/category'], { queryParams: { t: title, parentPageId: item.PageID } });
          break;
        case 'trails://':
            this.router.navigate(['/tabs/to-do'], { queryParams: { t: title, deepLink: link, parentPageId: item.PageID } });
            break;
        case 'deals://':
          this.router.navigateByUrl('/tabs/deals', {
            relativeTo: this.route,
            queryParams: { t: title }
          });
          break;
        case 'category://':
          this.router.navigate(['/tabs/category'], { queryParams: { t: title, parentPageId: item.PageID } });
          break;

        default:
          this.router.navigate(['/tabs/listings'], { queryParams: { t: item.PageName, rssLink: link, parentPageId: item.PageID } });
          break;
      }
    }

  }


}
