import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { AccessTokenClientService } from './access-token-client.service';
import { Injectable } from '@angular/core';
import { MessagesSdkAccessTokenUtilsService } from './messages-sdk-access-token-utils.service';
import { environment } from '@env/environment';

interface MessagesSDKToken {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_at: string;
}

@Injectable({
  providedIn: 'root'
})
export class ChannelsClientService {

  private BASE_URL = environment.messagesSDK.base_url;

  constructor(
    private http: HttpClient,
    private accessTokenService: AccessTokenClientService,
    private messagesSDKUtils: MessagesSdkAccessTokenUtilsService
  ) { }

  async getSubscribedChannels(deviceToken: string) {
    try {
      const accessToken = await this.accessTokenService.getToken();
      return new Promise<{
        Channels: {
          Name: string,
          ApplicationId: number,
          Id: number
        }[]
      }>((resolve, reject) => {
        const getChannelsUrl = `${this.BASE_URL}/api/v3/application/devices/android/${deviceToken}/channels`;
        this.http.get<{
          Channels: {
            Name: string,
            ApplicationId: number,
            Id: number
          }[]
        }>(getChannelsUrl, {
          headers: this.messagesSDKUtils.getAccessTokenHeader(accessToken)
        }).subscribe(channels => { resolve(channels); }, err => { reject(err); });
      });
    } catch (error) {
      throw error;
    }
  }

  async postSubscribeToChannels(channels: string[], deviceToken: string) {
    try {
      const accessToken = await this.accessTokenService.getToken();
      return new Promise<{
        SubscribedChannels: string[],
        Message?: string
      }>((resolve, reject) => {
        const subscribeChannelsUrl = `${this.BASE_URL}/api/v3/application/channels/subscribe`;
        const bodyParams = new HttpParams({
          fromObject: {
            Channels: channels,
            DeviceToken: deviceToken
          }
        });
        this.http.post<{
          SubscribedChannels: string[],
          Message?: string
        }>(subscribeChannelsUrl, bodyParams, {
          headers: this.messagesSDKUtils.getAccessTokenHeader(accessToken)
        }).subscribe(response => { resolve(response); }, err => { reject(err); });
      });
    } catch (error) {
      throw error;
    }
  }

  async postUnsubscribeToChannels(channels: string[], deviceToken: string) {
    try {
      const accessToken = await this.accessTokenService.getToken();
      return new Promise<{}>((resolve, reject) => {
        const unsubscribeChannelsUrl = `${this.BASE_URL}/api/v3/application/channels/unsubscribe`;
        const bodyParams = new HttpParams({
          fromObject: {
            Channels: channels,
            DeviceToken: deviceToken
          }
        });
        this.http.post<{}>(unsubscribeChannelsUrl, bodyParams, {
          headers: this.messagesSDKUtils.getAccessTokenHeader(accessToken)
        }).subscribe(response => { resolve(response); }, err => { reject(err); });
      });
    } catch (error) {
      throw error;
    }
  }

}
